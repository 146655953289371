"use client";

import { useThemeStorage } from "@/lib/theme/usetheme";
import clsx from "clsx";
import { useEffect, useState } from "react";

const timeForExcess = 10 * 1000;

export default function LoadingOverpage(props: {
  children?: React.ReactNode;
  loading?: boolean;
  description?: string;
}) {

  const { theme } = useThemeStorage();
  const [loadingTimeExceeded, setLoadingTimeExceeded] = useState(false);

  const show = props.loading === undefined ? true : props.loading;

  useEffect(() => {
    const e = setTimeout(() => {
      setLoadingTimeExceeded(true);
    }, timeForExcess);

    return () => {
      clearTimeout(e)
    }
  }, [])

  return (
    <div className={`${theme == "dark" ? "dark" : ""}`}>
      <div className={`fixed inset-0 z-40 flex items-center justify-center bg-background backdrop-blur-sm transition-all text-black dark:test-white  ${show ? 'opacity-100' : 'opacity-0 pointer-events-none'
        } `}>
        <div className="flex flex-col items-center gap-6 text-center">
          <div className={clsx("", {
            "site-loader": show
          })}></div>
          <div className="flex flex-col items-center gap-2">
            <h2 className="text-2xl font-bold mb-2 text-black dark:text-white">{props.children}</h2>
            {props.description && (
              <p className="text-muted-foreground text-black dark:text-white">{props.description}</p>
            )}
            {loadingTimeExceeded && (
              <div className="mt-4 text-black dark:text-white">
                Did it take too long?{" "}
                <a
                  onClick={() => window.location.reload()}
                  href="#"
                  className="text-primary hover:underline"
                >
                  Reload
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
